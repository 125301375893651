import logo2 from './Images/6b76fba9bd80c1a19899b4ac06dedb1e.jpg';
import './CSS/App.css';
import LoginButton from './Pages/LoginButton';
import HomePage from './Pages/HomePage';
import UploadPage from './Pages/UploadPage';
import DashboardPage from './Pages/DashboardPage';
import AdminPage from './Pages/AdminPage';
import FileUploadPage from './Pages/FileUploadPage';
import ProfilePage from './Pages/ProfilePage';
import NotificationPage from './Pages/NotificationPage';
import SupportPage from './Pages/SupportPage';
import BenchmarkPage from './Pages/BenchmarkPage';
import FeedbackPage from './Pages/FeedbackPage';
import Transform from './Pages/Transform';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { HashRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'; 
import authConfig from './authConfig';
import Footer from './Pages/Footer';

const msalInstance = new PublicClientApplication(authConfig);
const timeoutDuration = 10 * 60 * 1000; // 10 minutes in milliseconds

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(null); 
    const [userInfo, setUserInfo] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);  // Sidebar default is collapsed
    const [tableauToken, setTableauToken] = useState(null);
    const [isDarkMode] = useState(localStorage.getItem('theme') === 'dark'); // Load theme from localStorage

    const navigate = useNavigate();
    const location = useLocation(); 

    const backgroundStyle = {
        backgroundImage: `url(${logo2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    };

    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            setIsLoggedIn(true);
            setUserInfo(JSON.parse(storedUserInfo));
        } else {
            setIsLoggedIn(false); 
        }
    }, []); 

    const handleLogout = useCallback(() => {
        setIsLoggedIn(false);
        setUserInfo(null);
        setTableauToken(null);
        localStorage.removeItem('userInfo');
        console.log("Session timed out. Please log in again.");
        navigate('/signin'); 
    }, [navigate]);

    useEffect(() => {
        let timeout;
        if (isLoggedIn) {
            timeout = setTimeout(() => {
                handleLogout();
            }, timeoutDuration);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isLoggedIn, handleLogout]);

    const acquireTableauTokenSilent = async () => {
        try {
            const msalToken = await msalInstance.acquireTokenSilent({
                scopes: ['User.Read'],
            });

            const response = await axios.post('https://10ay.online.tableau.com/api/3.10/auth/signin', {
                credentials: {
                    token: msalToken.accessToken 
                }
            });

            return response.data.token;
        } catch (error) {
            console.error('Error acquiring Tableau token', error);
            return null;
        }
    };

    const isAdmin = userInfo?.roles?.includes('Admin'); 

    const ProtectedRoute = ({ children, isAdminRequired }) => {
        if (isLoggedIn === null) {
            return null;
        }
        if (!isLoggedIn) {
            return <Navigate to="/signin" />;
        }
        if (isAdminRequired && !isAdmin) {
            return <Navigate to="/" />;
        }
        return children;
    };

    if (isLoggedIn === null) {
        return <div>Loading...</div>;
    }

    return (
        <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <header>
                {!isLoggedIn && location.pathname !== '/signin' && (
                    <LoginButton setIsLoggedIn={setIsLoggedIn} setUserInfo={setUserInfo} setTableauToken={setTableauToken} />
                )}
                {isLoggedIn && (
                    <div className="header-buttons">
                        <h2
                            className="welcome-message"
                        >
                            Welcome, {userInfo?.name}!
                        </h2>
                    </div>
                )}
            </header>

            {/* Sidebar for Navigation Menu */}
            <div className={`sidebar ${isMenuOpen ? 'open' : 'closed'}`}>
                <div className="sidebar-toggle">
                    <button onClick={() => setIsMenuOpen(prev => !prev)}>
                        {isMenuOpen ? 'Collapse' : 'Expand'}
                    </button>
                </div>
                <ul>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/')}>
                            <span className="icon">🏠</span>
                            {isMenuOpen && <span className="title">Home Page</span>}
                        </button>
                    </li>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/upload')}>
                            <span className="icon">📊</span>
                            {isMenuOpen && <span className="title">Dashboard Page</span>}
                        </button>
                    </li>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/fileupload')}>
                            <span className="icon">📤</span>
                            {isMenuOpen && <span className="title">Upload Page</span>}
                        </button>
                    </li>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/benchmark')}>
                            <span className="icon">📈</span>
                            {isMenuOpen && <span className="title">Benchmark Page</span>}
                        </button>
                    </li>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/notification')}>
                            <span className="icon">🔔</span>
                            {isMenuOpen && <span className="title">Notification Page</span>}
                        </button>
                    </li>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/support')}>
                            <span className="icon">💬</span>
                            {isMenuOpen && <span className="title">Support Page</span>}
                        </button>
                    </li>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/feedback')}>
                            <span className="icon">💬</span>
                            {isMenuOpen && <span className="title">Feedback Page</span>}
                        </button>
                    </li>
                    <li>
                        <button className="sidebar-item" onClick={() => navigate('/transform')}>
                            <span className="icon">T</span>
                            {isMenuOpen && <span className="title">Transform</span>}
                        </button>
                    </li>
                    {isAdmin && (
                        <li>
                            <button className="sidebar-item" onClick={() => navigate('/admin')}>
                                <span className="icon">⚙️</span>
                                {isMenuOpen && <span className="title">Admin Page</span>}
                            </button>
                        </li>
                    )}
                    <li>
                        <button className="sidebar-item" onClick={async () => {
                            if (!tableauToken) {
                                const token = await acquireTableauTokenSilent();
                                setTableauToken(token);
                            }
                            navigate('/dashboard');
                        }}>
                            <span className="icon">🔒</span>
                            {isMenuOpen && <span className="title">REDACTED</span>}
                        </button>
                    </li>
                    {/* Add Profile and Sign Out buttons at the bottom */}
        {isLoggedIn && (
            <>
                <li>
                    <button className="sidebar-item" onClick={() => navigate('/profile')}>
                        <span className="icon">👤</span>
                        {isMenuOpen && <span className="title">Profile</span>}
                    </button>
                </li>
                <li>
                    <button className="sidebar-item" onClick={handleLogout}>
                        <span className="icon">🚪</span>
                        {isMenuOpen && <span className="title">Sign Out</span>}
                    </button>
                </li>
            </>
        )}
    </ul>
</div>

            <main style={backgroundStyle}>
                <Routes>
                    <Route path="/signin" element={isLoggedIn ? <Navigate to="/" /> : <LoginButton setIsLoggedIn={setIsLoggedIn} setUserInfo={setUserInfo} setTableauToken={setTableauToken} />} />
                    <Route path="/" element={isLoggedIn ? <HomePage isMenuOpen={isMenuOpen} /> : <Navigate to="/signin" />} />
                    <Route path="/upload" element={<ProtectedRoute><UploadPage userInfo={userInfo} /></ProtectedRoute>} />
                    <Route path="/fileupload" element={<ProtectedRoute><FileUploadPage /></ProtectedRoute>} />
                    <Route path="/dashboard" element={<ProtectedRoute><DashboardPage token={tableauToken} /></ProtectedRoute>} />
                    <Route path="/admin" element={<ProtectedRoute isAdminRequired><AdminPage /></ProtectedRoute>} />
                    <Route path="/profile" element={<ProtectedRoute><ProfilePage userInfo={userInfo} /></ProtectedRoute>} />
                    <Route path="/notification" element={<ProtectedRoute><NotificationPage /></ProtectedRoute>} />
                    <Route path="/support" element={<ProtectedRoute><SupportPage /></ProtectedRoute>} />
                    <Route path="/benchmark" element={<ProtectedRoute><BenchmarkPage /></ProtectedRoute>} />
                    <Route path="/feedback" element={<ProtectedRoute><FeedbackPage /></ProtectedRoute>} />
                    <Route path='/transform' element={<ProtectedRoute><Transform /></ProtectedRoute>} />
                </Routes>
            </main>

            <Footer />
        </div>
    );
}

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;