import React, { useState } from 'react';
import Papa from 'papaparse';

// Function to group similar terms
const groupSimilarItems = (items) => {
    const groupedItems = [];
    const categories = {
        steripath: ['Steripath Used?', 'Steripath Reason Not Used', 'Steripath YES', 'Steripath NO', 'Steri Path collection device used'],
        specimen: ['Specimen Source', 'Specimen Number', 'Specimen Draw Type', 'Specimen ID', 'Specimen Collector Credentials', 'Specimen Flags'],
        collection: ['Collection Date', 'Collection Department', 'Collection Location', 'Collection Instant', 'Collected Dt/Tm', 'Collection Site', 'Collection Site Laterality'],
        collector: ['Collector', 'Collector Role Type', 'Collector Name', 'Collector Role'],
        contaminant: ['Contaminants', 'Contaminant', 'Contaminates', 'Contam', 'Contaminant ?'],
        organism: ['Organism', 'Organisms', 'Pathogen (C)'],
        lab: ['Lab Question Details', 'Lab Steripath Not Used Answer', 'Lab Steripath Question Answer', 'Lab Order Class', 'Lab Internal Comments'],
        other: []
    };

    // Group items based on categories
    items.forEach(item => {
        let grouped = false;
        for (const [category, terms] of Object.entries(categories)) {
            if (terms.some(term => item.includes(term))) {
                if (!groupedItems[category]) {
                    groupedItems[category] = new Set();
                }
                groupedItems[category].add(item); 
                grouped = true;
                break;
            }
        }
        if (!grouped) {
            categories.other.push(item);
        }
    });

    // Convert grouped items to a format for rendering
    const groupedDisplay = [];
    Object.keys(groupedItems).forEach(category => {
        groupedDisplay.push({ category, items: [...groupedItems[category]] });
    });
    groupedDisplay.push({ category: 'Other', items: categories.other });

    return groupedDisplay;
};

const csvHeaders = [
    'Collection Date', 'Specimen Number', 'Specimen Source', 'Steripath Used?', 'Contaminant ?', 'Collection Department', 
    'Collector Name', 'Organism', 'Lab Order Class'
];

const templateData = [
    { 'Collection Date': '', 'Specimen Number': '', 'Specimen Source': '', 'Steripath Used?': '', 'Contaminant ?': '', 
      'Collection Department': '', 'Collector Name': '', 'Organism': '', 'Lab Order Class': '', 'Draw Type': '', 'User Role': '' }
];

const Transform = () => {
    const [dragging, setDragging] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState('');
    const [csvFile, setCsvFile] = useState(null);
    const [failedHeaders, setFailedHeaders] = useState([]);
    const [groupedItemsVisible, setGroupedItemsVisible] = useState(false);
    const [uploadedData, setUploadedData] = useState([]);
    const [uploadedHeaders, setUploadedHeaders] = useState([]);

    const items = [
        'Total Draws', 'Draws w/ Device', 'Complaince %', 'Contaminations', 'Contaminations w/ Device', 'Contamination Rate', 
        'Contamination Rate w/ Device', 'Test Specimen ID', 'Collection Instant', 'Collection Department', 'Collector', 'Organism', 
        'Lab Question Details', 'Lab Steripath Not Used Answer', 'Lab Steripath Question Answer', 'Cont Cut', 'Cont Line', 
        'Collection Location', 'Collector Role Type', 'Collected Dt/Tm', 'Specimen Number', 'Specimen Source', 'Draw Type', 
        'Order Mode', 'Steripath Used?', 'Patient Location or Collection or Accession From Location', 
        'Patient Location or Collection or Accession From Department', 'SPECIMEN_NUMBER', 'Collector_Role_Type', 'Steripath Reason Not Used', 
        'Organisms', 'Contaminates', 'Abnormality', 'Last Receive Instant', 'Reason Steripath Not Used', 'Value', 'Contam', 
        'Pathogen (C)', 'Lab Order Class', 'Collection Date', 'Verified Date', 'Contaminant', 'Volume Correct', 'Steripath Reason Not Used', 
        'Comments', 'Lab Internal Comments', 'Patient/MRN', 'Specimen ID', 'MPI', 'Contaminant ?', 'Correct Volume ?', 'Month', 'Year', 
        'Date', 'Count', 'Total', 'Steripath YES', 'Steripath NO', 'Steripath Unknown', 'Week', 'MRN', 'Account', 'Patient Age', 
        'Collect TS', 'Order ID', 'Location', 'Staff Category', 'Collector Role', 'Collector Name', 'Specimen Draw Type', 
        'Accessioned From Dept', 'Specimen Collector Credentials', 'Collection Site Laterality', 'Collection Site', 'Attributed Hospital', 
        'Specimen Flags', 'Spec Collecting Dept', 'Age On Admit', 'Collected Date', 'BC Result', 'Steri Path collection device used', 
        'Blood Culture collection site', 'Positive', 'Contaminant'
    ];

    const uniqueItems = [...new Set(items)];

    const groupedItems = groupSimilarItems(uniqueItems);

    const categoryColors = {
        steripath: 'lightblue', specimen: 'lightgreen', collection: 'lightyellow', collector: 'lightcoral', contaminant: 'lightpink',
        organism: 'lightseagreen', lab: 'lightgray', other: 'lightgoldenrodyellow'
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragging(false);
        const file = event.dataTransfer.files[0];
        if (file && file.type === 'text/csv') {
            Papa.parse(file, {
                complete: (result) => {
                    const headers = result.data[0];
                    const rows = result.data.slice(1);
                    setCsvFile(file);
                    setUploadedHeaders(headers);
                    setUploadedData(rows);
                },
                header: false
            });
        } else {
            alert("Please drop a CSV file.");
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const downloadTemplateCSV = () => {
        const csv = Papa.unparse({
            fields: csvHeaders,
            data: templateData,
        });

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'template.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const verifyCsvHeaders = () => {
        if (csvFile) {
            Papa.parse(csvFile, {
                complete: (result) => {
                    const uploadedHeaders = result.data[0];
                    const failedHeaderLog = [];

                    csvHeaders.forEach((header, index) => {
                        if (uploadedHeaders[index] !== header) {
                            failedHeaderLog.push({
                                uploaded: uploadedHeaders[index] || 'N/A',
                                required: header
                            });
                        }
                    });

                    if (failedHeaderLog.length === 0) {
                        setVerificationStatus('Verification successful');
                        setFailedHeaders([]);
                    } else {
                        setVerificationStatus('Verification failed');
                        setFailedHeaders(failedHeaderLog);
                    }
                },
                header: false
            });
        } else {
            setVerificationStatus('No file selected');
        }
    };

    const handleDragStart = (event, index) => {
        event.dataTransfer.setData("text/plain", index);
    };

    const handleDropReorganize = (event, targetIndex) => {
        const draggedIndex = event.dataTransfer.getData("text/plain");
        const newFailedHeaders = [...failedHeaders];
        const draggedItem = newFailedHeaders[draggedIndex];
        newFailedHeaders.splice(draggedIndex, 1);
        newFailedHeaders.splice(targetIndex, 0, draggedItem);
        setFailedHeaders(newFailedHeaders);
    };

    const toggleGroupedItemsVisibility = () => {
        setGroupedItemsVisible(prevState => !prevState);
    };

    // Function to download the reorganized CSV
    const downloadReorganizedCSV = () => {
        const reorganizedHeaders = failedHeaders.map(item => item.uploaded);
        const reorganizedRows = uploadedData.map(row => {
            const reorganizedRow = [];
            reorganizedHeaders.forEach(header => {
                const originalIndex = uploadedHeaders.indexOf(header);
                reorganizedRow.push(row[originalIndex]);
            });
            return reorganizedRow;
        });

        const reorganizedCSV = Papa.unparse({
            fields: reorganizedHeaders,
            data: reorganizedRows,
        });

        const blob = new Blob([reorganizedCSV], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'reorganized.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div>
            <h3>Hello, Unique</h3>
            <button onClick={toggleGroupedItemsVisibility} className="App-button" style={{ marginBottom: '20px' }}>
                {groupedItemsVisible ? 'Hide Grouped Items' : 'Show Grouped Items'}
            </button>

            {groupedItemsVisible && (
                <div>
                    {groupedItems.map((group, index) => (
                        <div key={index}>
                            <h2 style={{ color: categoryColors[group.category.toLowerCase()] }}>
                                {group.category}
                            </h2>
                            {group.items.map((item, itemIndex) => (
                                <h1 key={itemIndex}>{item}</h1>
                            ))}
                        </div>
                    ))}
                </div>
            )}

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button className="App-button" onClick={downloadTemplateCSV}>
                    Download CSV Template
                </button>
                <p style={{ color: 'black' }}>Click to download a CSV template with the standard headers.</p>
            </div>

            <div
                style={{
                    border: '2px dashed #ccc',
                    padding: '20px',
                    textAlign: 'center',
                    marginTop: '20px',
                    backgroundColor: dragging ? '#f0f8ff' : '#fff'
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <p style={{ color: 'black' }}>{dragging ? 'Release to drop the file' : 'Drag and drop your CSV file here'}</p>
            </div>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button onClick={verifyCsvHeaders} className="App-button">
                    Verify CSV Headers
                </button>
            </div>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <p>{verificationStatus}</p>
                {verificationStatus === 'Verification failed' && (
                    <div style={{ marginTop: '20px' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <div style={{ width: '45%' }}>
                                <h3 style={{ color: 'black' }}>Uploaded Headers</h3>
                                <ul style={{ listStyleType: 'none' }}>
                                    {failedHeaders.map((header, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                color: 'black',
                                                padding: '10px',
                                                background: '#f4f4f4',
                                                marginBottom: '5px',
                                                borderRadius: '5px',
                                                cursor: 'move'
                                            }}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDrop={(e) => handleDropReorganize(e, index)}
                                            onDragOver={(e) => e.preventDefault()}
                                        >
                                            {header.uploaded}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div style={{ width: '45%' }}>
                                <h3 style = {{ color: 'black'}}>Required Headers</h3>
                                <ul style={{ listStyleType: 'none' }}>
                                    {csvHeaders.map((header, index) => (
                                        <li key={index} style={{ color: 'black', padding: '10px', background: '#f4f4f4', marginBottom: '5px', borderRadius: '5px' }}>
                                            {header}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            <button onClick={downloadReorganizedCSV} className="App-button">
                                Download Reorganized CSV
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Transform;